import { Badge, Box, Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function SelectInsurers({ insurers, setInsurers, sx, fetchQuotationByKey, showResult, pendingTasks, setPendingTasks,
  displayStatus, setDisplayStatus
}) {
  const [selectAll, setSelectAll] = useState(false);

  const { lifeline, bwho, company, oil_gas, first_choice, ihhp, vumi, my_health_indonesia, my_health_singapore, my_health_vietnam, optimum_global, morgan_price } = insurers;


  function handleSelectAllChange(newValue) {
    const allChecked = checkIfAllChecked();
    const updatedInsurers = {};

    for (const [key, value] of Object.entries(insurers)) {
      updatedInsurers[key] = newValue;
    }

    setInsurers(updatedInsurers);
    setSelectAll(newValue);
  }

  function checkIfAllChecked() {
    for (const [key, value] of Object.entries(insurers)) {
      if (!value) {
        return false;
      }
    }

    return true;
  }

  function checkIfIndeterminate() {
    const allChecked = checkIfAllChecked();

    if (allChecked) {
      return false;
    }

    for (const [key, value] of Object.entries(insurers)) {
      if (value == true) {
        return true;
      }
    }

    return false;
  }

  const handleChange = (event) => {
    const key = event.target.name;
    const checked = event.target.checked;

    if (checked && showResult) {
      // fetchQuotationByKey(key);
      const _pendingTasks = JSON.parse(JSON.stringify(pendingTasks));


      if (!_pendingTasks.includes(key)) {
        _pendingTasks.push(key);
      }

      setPendingTasks([..._pendingTasks]);
    } else {
      displayStatus[key] = false;
      setDisplayStatus(displayStatus);
    }


    setInsurers({
      ...insurers,
      [key]: checked,
    });
  };

  return (
    <Paper sx={{ p: 2, ...sx, position: 'sticky' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>

        <Typography fontWeight={600} fontSize={21} sx={{ mr: 2 }}>Select Insurer</Typography>
        {/* <FormControlLabel
          control={
            <Checkbox checked={selectAll} onChange={(e) => { handleSelectAllChange(e.target.checked) }} indeterminate={checkIfIndeterminate()} />
          }
          label="ALL INSURERS"
        /> */}
      </Box>
      <FormControlLabel
        control={
          <Checkbox checked={lifeline} onChange={handleChange} name="lifeline" />
        }
        label="BUPA Lifeline"
      />
      <FormControlLabel
        control={
          <Checkbox checked={bwho} onChange={handleChange} name="bwho" />
        }
        label="BWHO"
      />
      <FormControlLabel
        control={
          <Checkbox checked={company} onChange={handleChange} name="company" />
        }
        label="BUPA Company"
      />
      <FormControlLabel
        control={
          <Checkbox checked={oil_gas} onChange={handleChange} name="oil_gas" />
        }
        label="Oil & Gas"
      />
      <FormControlLabel
        control={
          <Checkbox checked={first_choice} onChange={handleChange} name="first_choice" />
        }
        label="First Choice"
      />

      <FormControlLabel
        control={
          <Checkbox checked={ihhp} onChange={handleChange} name="ihhp" />
        }
        label="IHHP"
      />
      <FormControlLabel
        control={
          <Checkbox checked={vumi} onChange={handleChange} name="vumi"
          />
        }
        label="VUMI"
      />
      <FormControlLabel
        control={
          <Checkbox checked={my_health_indonesia} onChange={handleChange} name="my_health_indonesia" />
        }
        label="MyHealth ID"
      />
      <FormControlLabel
        control={
          <Checkbox checked={my_health_singapore} onChange={handleChange} name="my_health_singapore" />
        }
        label="MyHealth SG"
      />
      <FormControlLabel
        control={
          <Checkbox checked={my_health_vietnam} onChange={handleChange} name="my_health_vietnam" />
        }
        label="MyHealth VN"
      />
      <FormControlLabel
        control={
          <Checkbox checked={optimum_global} onChange={handleChange} name="optimum_global" />
        }
        label="Optimum Global"
      />
      

      <FormControlLabel
        control={
          <Checkbox checked={morgan_price} onChange={handleChange} name="morgan_price" />
        }
        label="Morgan Price"
      />
    </Paper>
  );
}