import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Grid, Checkbox, TextField, InputAdornment, Select, MenuItem, Skeleton, List, ListItem, ListItemText, colors, Button } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import TableRatesRow from './TableRatesRow';
import getSafe from '../utils/getSafe';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';



const MyHealthSingapore_Rates = forwardRef(({ sx, my_health_singapore, clients, currency, _currency, selectedPlans,
  setSelectedPlans, loadingStatus, response, deductibles, setDeductibles, addToPendingTasks, removeFromPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  myHealth_HS_Plan, setMyHealth_HS_Plan, myHealth_HS_IP_Network, setMyHealth_HS_IP_Network, myHealth_HS_Deductible,
  setMyHealth_HS_Deductible, myHealth_OP_Plan, setMyHealth_OP_Plan,
  myHealth_OP_CoIns, setMyHealth_OP_CoIns, myHealth_MA_Plan,
  setMyHealth_MA_Plan, myHealth_DN_Plan, setMyHealth_DN_Plan,
  getCoInsLabel, areaOfCoverage, countryOfResidence,
  specificAreaOfCoverage, setSpecificAreaOfCoverage,
  setShowImageCopy }, ref) => {

  const id = 'my_health_singapore';

  const { appConfig } = useContext(ConfigContext);

  const containerRef = useRef(null);

  const co_ins = getSafe(() => appConfig.content.specifics.my_health_indonesia.co_ins);
  const plans = getSafe(() => appConfig.content.specifics.my_health_singapore.plans);
  const area_of_cover = getSafe(() => appConfig.content.specifics.my_health_singapore.area_of_cover);
  const family_discount = getSafe(() => appConfig.content.specifics.my_health_singapore.family_discount);
  const ip_network = getSafe(() => appConfig.content.specifics.my_health_singapore.ip_network);

  const specific_area_of_coverages = getSafe(() => appConfig.content.set2_area_of_coverage);

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false,
    repatriation: false
  });



  const [validationErrorMessages, setValidationErrorMessages] = useState([]);

  useEffect(() => {
    const errors = validateRequirements();

    if (errors.length > 0) {
      removeFromPendingTasks([id]);
    }
  }, [countryOfResidence, myHealth_MA_Plan, myHealth_HS_Plan, myHealth_HS_IP_Network, myHealth_HS_Deductible, myHealth_OP_Plan, clients, getAreaOfCoverage()]);

  useEffect(() => {
    const idx = selectedPlans.indexOf(id);

    if (my_health_singapore && checkIfValidForPremiumSummary()) {
      if (idx == -1) {
        selectedPlans.push(id);
      }
    } else {
      if (idx != -1) {
        selectedPlans.splice(idx, 1);
      }
    }

    setSelectedPlans([...selectedPlans]);
  }, [my_health_singapore, checkIfValidForPremiumSummary()]);

  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);


  }, [response, discounts]);

  useEffect(() => {
    if (myHealth_HS_Deductible.length == 0) return;

    deductibles[id] = myHealth_HS_Deductible[0];
  }, [myHealth_HS_Deductible]);


  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;


    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'my_health_singapore': getSubTotalRate(i),
        'currency': 'SGD',
        'plan_label': getPlanLabel()
      })
    }

    return computedRates;
  }

  function getPlanLabel() {
    if (clients.length === 0) return '';

    let label = '';

    label = 'HS ' + myHealth_HS_Plan[0];

    if (myHealth_OP_Plan[0] !== 'N/A') {
      label += ' + ' + 'OP ' + myHealth_OP_Plan[0];
    }

    if (myHealth_MA_Plan[0] !== 'N/A') {
      label += ' + ' + 'MA ' + myHealth_MA_Plan[0];
    }

    if (myHealth_DN_Plan[0] !== 'N/A') {
      label += ' + ' + 'DN ' + myHealth_DN_Plan[0];
    }

    return label;
  }

  function checkIfValidForPremiumSummary() {
    if (clients.length == 0) return false;
    // if (clients.length == 1) return true;

    // let hs = myHealth_HS_Plan[0], op = myHealth_OP_Plan[0], ma = myHealth_MA_Plan[0], dn = myHealth_DN_Plan[0],
    //   deductible = myHealth_HS_Deductible[0];

    // for (let i = 1; i < clients.length; i++) {
    //   if (hs != myHealth_HS_Plan[i]) return false;

    //   if (deductible != myHealth_HS_Deductible[i]) return false;

    //   if (op != myHealth_OP_Plan[i]) return false;

    //   if (ma != myHealth_MA_Plan[i]) return false;

    //   if (dn != myHealth_DN_Plan[i]) return false;

    // }


    return true;
  }

  function handleDeductibleChange(e) {
    const _deductibles = JSON.parse(JSON.stringify(deductibles));

    _deductibles.lifeline = e.target.value;

    setDeductibles(_deductibles);

    addToPendingTasks([id]);
  }

  function scrollToView() {
    setTimeout(() => {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
  }

  function onInputDiscountChange(e) {
    var numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Remove leading zeros
    numericValue = numericValue.replace(/^0+/, '');

    if (numericValue > 100) {
      numericValue = 100;
    }

    discounts[id] = numericValue;

    setDiscounts({ ...discounts });
  }

  function getRate(clientIndex, benefit) {
    if (!response) return '';

    if (!response.rates[clientIndex]) return '';

    return response.rates[clientIndex][benefit];
  }

  function getSubTotalRate(clientIndex) {
    if (!response) return '';

    if (!response.rates[clientIndex]) return 0;

    let hs_rates = getRate(clientIndex, 'hs');
    let op_rates = getRate(clientIndex, 'op');
    let ma_rates = getRate(clientIndex, 'ma');
    let dn_rates = getRate(clientIndex, 'dn');

    if (hs_rates === '') {
      hs_rates = 0;
    }

    if (op_rates === '') {
      op_rates = 0;
    }

    if (ma_rates === '') {
      ma_rates = 0;
    }

    if (dn_rates === '') {
      dn_rates = 0;
    }



    return hs_rates + op_rates + ma_rates + dn_rates;
  }

  function getTotalPremium() {
    let totalPremium = 0;

    for (let i = 0; i < clients.length; i++) {
      totalPremium += getSubTotalRate(i);
    }

    return totalPremium;
  }

  function formatRate(rate) {
    if (typeof rate === 'string' && rate === '') {
      return '----';
    }

    if (isNaN(rate) || rate == 'Unavailable' || typeof rate === 'string') {
      return <span style={{ color: '#f44336' }}>{'Unavailable'}</span>
    }

    if (isNaN(rate)) {
      return rate;
    }

    return getCurrencySign() + rate.toLocaleString('en-PH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function getCurrencySign() {
    return 'S$';
  }

  function getFamilyDiscountLabel() {
    const clients_count = clients.length;

    if (!family_discount) {
      return '0%';
    }

    let discount = 0;

    if (clients_count > 7) {
      discount = family_discount['>7'];
    } else {
      discount = family_discount[clients_count] || 0;
    }

    return `${discount * 100}%`;
  }

  function getAreaOfCoverage() {

    let generalAreaOfCoverage = areaOfCoverage;

    if (specificAreaOfCoverage[id] === 'parent') {
      generalAreaOfCoverage = areaOfCoverage;
    } else {
      generalAreaOfCoverage = specificAreaOfCoverage[id];
    }

    const specificArea = area_of_cover[generalAreaOfCoverage] || '';

    return specificArea;
  }

  function getSpecificAreaOfCoverage(clientIndex) {

    if (clients.length == 0) return '';

    if (!clients[clientIndex]) return '';

    const specificArea = area_of_cover[clients[clientIndex].area_of_coverage] || '';

    return specificArea;
  }

  function validateRequirements() {
    const errors = [];

    // if (!validateCountryOfResidence()) {
    //   errors.push('Invalid Country of Residence. Only Available in Indonesia');
    // }

    const maternityErrors = validateMaternityQualification();

    if (maternityErrors.length > 0) {
      errors.push(maternityErrors);
    }

    const ipNetworkErrors = validateIPNetwork();

    if (ipNetworkErrors.length > 0) {
      errors.push(ipNetworkErrors);
    }

    setValidationErrorMessages(errors);

    return errors;
  }

  function validateCountryOfResidence() {
    return countryOfResidence === 'Indonesia';
  }

  function validateMaternityQualification() {
    const validations = {
      validation_1: {
        error: false,
        message: 'Maternity is only available if Nil deductible, Elite or Extensive Hospital and Surgery, and an Outpatient plan have been selected. Please change your selection.'
      },
      validation_2: {
        error: false,
        message: "Maternity is only available to Females aged from 19 to 45. Please adjust your selection and remove maternity from ineligible members' selection"
      }
    }

    for (let i = 0; i < myHealth_MA_Plan.length; i++) {
      if (myHealth_MA_Plan[i] !== 'N/A') {
        // validation 1
        if (myHealth_HS_Deductible[i] !== 'Nil') {
          validations['validation_1']['error'] = true;
        }

        if (!(['Elite', 'Extensive'].includes(myHealth_HS_Plan[i]))) {
          validations['validation_1']['error'] = true;
        }

        if (myHealth_OP_Plan[i] === 'N/A') {
          validations['validation_1']['error'] = true;
        }

        // validation 2
        if (clients[i].gender !== 'Female') {
          validations['validation_2']['error'] = true;
        }

        if (clients[i].age < 19 || clients[i].age > 45) {
          validations['validation_2']['error'] = true;
        }
      }
    }

    const errorMessages = [];

    if (validations['validation_1']['error']) {
      errorMessages.push(validations['validation_1']['message'])
    } else if (validations['validation_2']['error']) {
      errorMessages.push(validations['validation_2']['message'])
    }


    return errorMessages;
  }

  function validateIPNetwork() {
    const validations = {
      validation_1: {
        error: false,
        message: 'SIP IP network option is only available for Worldwide Exluding USA. Please adjust your selection'
      }
    }

    const areaOfCoverage = getAreaOfCoverage();

    for (let i = 0; i < myHealth_HS_IP_Network.length; i++) {
      if (myHealth_HS_IP_Network[i] === 'SIP' && areaOfCoverage !== 'Worldwide Excl. U.S.A.') {
        validations['validation_1']['error'] = true;
      }
    }

    const errorMessages = [];

    if (validations['validation_1']['error']) {
      errorMessages.push(validations['validation_1']['message'])
    }


    return errorMessages;
  }

  function getMA_Plans() {
    const ma_plans = [...plans];

    const index = ma_plans.indexOf('Core');

    if (index > -1) {
      ma_plans.splice(index, 1);
    }

    return ma_plans;

  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2, scrollMarginTop: '200px' }} variant='outlined' ref={containerRef}>
        <Typography variant='h5' sx={{ color: '#fff', background: '#004161', px: 2, py: 1 }}>MyHEALTH Singapore</Typography>
        <Box sx={{ p: 2 }}>

          <Grid container>
            <Grid item xs={2}><Typography fontSize={18} fontWeight={600}>Select Area Of Cover</Typography></Grid>
            <Grid item xs={2}>
              <Select
                size='small'
                value={specificAreaOfCoverage[id]}
                onChange={(e) => {
                  specificAreaOfCoverage[id] = e.target.value;
                  setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                  addToPendingTasks([id]);
                }}
                sx={{ fontSize: 12, height: 40, width: '190px', mt: -2 }}
                fullWidth
                disabled={loadingStatus[id]}
              >
                {
                  specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                    <MenuItem key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                  ))
                }

              </Select>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>

            <Grid item xs={2}>
              <Typography fontSize={18}>
                Family Discount: <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>{getFamilyDiscountLabel()}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography fontSize={18}>
                Area of Coverage: <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>{getAreaOfCoverage()}</span>
              </Typography>
            </Grid>
          </Grid>

          {
            validationErrorMessages.length > 0 &&
            <ul style={{ color: colors.red['A700'] }}>
              {
                validationErrorMessages.map(validationErrorMessage => {
                  return <li>{validationErrorMessage}</li>
                })
              }
            </ul>
          }

          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-my-health-singapore-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>


          <TableContainer component={Paper}>
            <Table id='table-my-health-singapore-rates' size='small'>
              <TableHead sx={{ background: '#004161' }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, color: '#fff', width: 200 }}>Clients</TableCell>
                  <TableCell colSpan={4} sx={{ fontWeight: 600, color: '#fff', borderLeft: 1, borderColor: '#a3a3a3' }}>Hospital & Surgery</TableCell>
                  <TableCell colSpan={3} sx={{ fontWeight: 600, color: '#fff', borderLeft: 1, borderColor: '#a3a3a3' }}>Outpatient</TableCell>
                  <TableCell colSpan={2} sx={{ fontWeight: 600, color: '#fff', borderLeft: 1, borderColor: '#a3a3a3' }}>Maternity</TableCell>
                  <TableCell colSpan={2} sx={{ fontWeight: 600, color: '#fff', borderLeft: 1, borderColor: '#a3a3a3' }}>Dental</TableCell>
                  <TableCell sx={{ fontWeight: 600, color: '#fff', borderLeft: 1, borderColor: '#a3a3a3' }}>Subtotal</TableCell>
                </TableRow>


              </TableHead>
              <TableBody>
                <TableRow sx={{ background: '#C9D213' }}>
                  <TableCell></TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600, borderLeft: 1, borderColor: '#a3a3a3' }}>Plan & Room</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>IP Network</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>Deductible</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>Premium</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600, borderLeft: 1, borderColor: '#a3a3a3' }}>Plan</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>Co-ins.</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>Premium</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600, borderLeft: 1, borderColor: '#a3a3a3' }}>Plan</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>Premium</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600, borderLeft: 1, borderColor: '#a3a3a3' }}>Plan</TableCell>
                  <TableCell sx={{ color: '#004161', fontWeight: 600 }}>Premium</TableCell>
                  <TableCell sx={{ borderLeft: 1, borderColor: '#a3a3a3' }}></TableCell>
                </TableRow>
                {
                  clients.map((client, client_idx) => {
                    return (
                      <TableRow key={client.uid}>
                        {
                          client.full_name.trim() === '' ?
                            <TableCell sx={{ fontWeight: 600 }} key={client.id}>_</TableCell>
                            :
                            <TableCell sx={{ fontWeight: 600 }} key={client.id}>
                              <div>{client.full_name}
                                <span> ({client.gender == 'Male' ? 'M' : 'F'}) ({client.age}) </span></div>
                              <div>{client.country_of_residence}</div>
                            </TableCell>
                        }
                        <TableCell style={{ borderLeft: '1px solid #a3a3a3' }}>
                          <CustomSelect2 items={[...plans]}
                            value={myHealth_HS_Plan[client_idx]}
                            onChange={(e) => {
                              myHealth_HS_Plan[client_idx] = e.target.value;
                              setMyHealth_HS_Plan([...myHealth_HS_Plan]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomSelect2 items={ip_network}
                            value={myHealth_HS_IP_Network[client_idx]}
                            onChange={(e) => {
                              myHealth_HS_IP_Network[client_idx] = e.target.value;
                              setMyHealth_HS_IP_Network([...myHealth_HS_IP_Network]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }}
                            isZeroDeductibleUniform={false} />
                        </TableCell>
                        <TableCell>
                          <CustomSelect2 items={getSafe(() => appConfig.content.deductibles.my_health_singapore['SGD'])}
                            value={myHealth_HS_Deductible[client_idx]}
                            onChange={(e) => {
                              myHealth_HS_Deductible[client_idx] = e.target.value;
                              setMyHealth_HS_Deductible([...myHealth_HS_Deductible]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }} />
                        </TableCell>
                        {
                          loadingStatus[id] ?
                            <TableCell width={70}><Skeleton variant="rounded" width='100%' height={20} /></TableCell>
                            :
                            <TableCell width={70}>{formatRate(getRate(client_idx, 'hs'))}</TableCell>
                        }

                        <TableCell style={{ borderLeft: '1px solid #a3a3a3' }}>
                          <CustomSelect2 items={['N/A', ...plans]}
                            value={myHealth_OP_Plan[client_idx]}
                            onChange={(e) => {
                              myHealth_OP_Plan[client_idx] = e.target.value;
                              setMyHealth_OP_Plan([...myHealth_OP_Plan]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }} />
                        </TableCell>
                        <TableCell>
                          <CustomSelect2 items={getCoInsLabel()}
                            value={myHealth_OP_CoIns[client_idx]}
                            onChange={(e) => {
                              myHealth_OP_CoIns[client_idx] = e.target.value;
                              setMyHealth_OP_CoIns([...myHealth_OP_CoIns]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }}
                            isZeroDeductibleUniform={false}
                          />
                        </TableCell>
                        {
                          loadingStatus[id] ?
                            <TableCell width={70}><Skeleton variant="rounded" width='100%' height={20} /></TableCell>
                            :
                            <TableCell width={70}>{formatRate(getRate(client_idx, 'op'))}</TableCell>
                        }
                        <TableCell style={{ borderLeft: '1px solid #a3a3a3' }}>
                          <CustomSelect2 items={['N/A', ...getMA_Plans()]}
                            value={myHealth_MA_Plan[client_idx]}
                            onChange={(e) => {
                              myHealth_MA_Plan[client_idx] = e.target.value;
                              setMyHealth_MA_Plan([...myHealth_MA_Plan]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }} />
                        </TableCell>
                        {
                          loadingStatus[id] ?
                            <TableCell width={70}><Skeleton variant="rounded" width='100%' height={20} /></TableCell>
                            :
                            <TableCell width={70}>{formatRate(getRate(client_idx, 'ma'))}</TableCell>
                        }
                        <TableCell style={{ borderLeft: '1px solid #a3a3a3' }}>
                          <CustomSelect2 items={['N/A', ...plans]}
                            value={myHealth_DN_Plan[client_idx]}
                            onChange={(e) => {
                              myHealth_DN_Plan[client_idx] = e.target.value;
                              setMyHealth_DN_Plan([...myHealth_DN_Plan]);

                              addToPendingTasks([id]);
                            }}
                            sx={{ width: '100%' }} />
                        </TableCell>
                        {
                          loadingStatus[id] ?
                            <TableCell width={70}><Skeleton variant="rounded" width={70} height={20} /></TableCell>
                            :
                            <TableCell width={70}>{formatRate(getRate(client_idx, 'dn'))}</TableCell>
                        }
                        {
                          loadingStatus[id] ?
                            <TableCell style={{ borderLeft: '1px solid #a3a3a3' }} width={70}><Skeleton variant="rounded" width='100%' height={20} /></TableCell>
                            :
                            <TableCell style={{ borderLeft: '1px solid #a3a3a3' }}>{formatRate(getSubTotalRate(client_idx))}</TableCell>
                        }

                      </TableRow>
                    )
                  })
                }
                <TableRow>
                  <TableCell colSpan={10} sx={{ py: 2.7 }}></TableCell>
                  <TableCell colSpan={2} sx={{ fontWeight: 600 }}>Annual Premium USD</TableCell>
                  <TableCell sx={{ fontWeight: 600, background: '#C9D213', color: '#004161' }} style={{ borderLeft: '1px solid #a3a3a3' }}>
                    {
                      loadingStatus[id] ?
                        <Skeleton variant="rounded" width='100%' height={20} />
                        :
                        formatRate(getTotalPremium())
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

          </TableContainer>

          {
            !checkIfValidForPremiumSummary() &&
            <Typography sx={{ mt: 2, color: colors.orange[400] }}>Not valid for Premium Summary. Clients plans are not the same.</Typography>
          }


          {/* <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size='small' sx={{ minWidth: 650 }}>
              <TableBody sx={{ background: '#004161' }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, color: '#fff', width: 120 }}>Clients</TableCell>
                  <TableCell sx={{ width: 150 }}></TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ maxWidth: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ maxWidth: 200, fontWeight: 600, color: '#fff' }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div>  </TableCell>
                    })
                  }
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={3} sx={firstColStyles}>Hospital & Surgery</TableCell>
                  <TableCell sx={secondColStyles}>Plan & Room</TableCell>
                  <TableCell sx={thirdColStyles}>
                    <select>
                      <option value="">Elite</option>
                      <option value="">Extensive</option>
                      <option value="">Essential</option>
                      <option value="">Core</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondColStyles}>Deductible</TableCell>
                  <TableCell sx={thirdColStyles}>
                    <select>
                      <option value="">Nil</option>
                      <option value="">Deductible</option>
                      <option value="">Deductible</option>
                      <option value="">Deductible</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondColStyles}>Premium</TableCell>
                  <TableCell sx={thirdColStyles}>$1000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={3} sx={firstColStyles}>Outpatient</TableCell>
                  <TableCell sx={secondColStyles}>Plan</TableCell>
                  <TableCell sx={thirdColStyles}>
                    <select>
                      <option value="">Elite</option>
                      <option value="">Extensive</option>
                      <option value="">Essential</option>
                      <option value="">Core</option>
                    </select>
                  </TableCell>


                </TableRow>
                <TableRow>
                  <TableCell sx={secondColStyles}>Co-ins.</TableCell>
                  <TableCell sx={thirdColStyles}>
                    <select>
                      <option value="">Nil</option>
                      <option value="">Deductible</option>
                      <option value="">Deductible</option>
                      <option value="">Deductible</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondColStyles}>Premium</TableCell>
                  <TableCell sx={thirdColStyles}>$1000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} sx={firstColStyles}>Maternity</TableCell>
                  <TableCell sx={secondColStyles}>Plan</TableCell>
                  <TableCell sx={thirdColStyles}>
                    <select>
                      <option value="">Elite</option>
                      <option value="">Extensive</option>
                      <option value="">Essential</option>
                      <option value="">Core</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondColStyles}>Premium</TableCell>
                  <TableCell sx={thirdColStyles}>$1000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} sx={firstColStyles}>Dental</TableCell>
                  <TableCell sx={secondColStyles}>Plan</TableCell>
                  <TableCell sx={thirdColStyles}>
                    <select>
                      <option value="">Elite</option>
                      <option value="">Extensive</option>
                      <option value="">Essential</option>
                      <option value="">Core</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondColStyles}>Premium</TableCell>
                  <TableCell sx={thirdColStyles}>$1000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={firstColStyles}>Subtotal</TableCell>
                  <TableCell sx={thirdColStyles}>$30000</TableCell>
                </TableRow>


              </TableBody>
            </Table>

          </TableContainer> */}
        </Box>


      </Paper>

    </Box>
  );
});

export default MyHealthSingapore_Rates;