import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Checkbox, Grid, TextField, InputAdornment, Select, MenuItem, colors, Button } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import TableRatesRow from './TableRatesRow';
import getSafe from '../utils/getSafe';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const BWHO_Rates = forwardRef(({ sx, clients, loadingStatus, currency, selectedPlans, setSelectedPlans,
  response, deductibles, setDeductibles, addToPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  specificAreaOfCoverage, setSpecificAreaOfCoverage, areaOfCoverage,
  setShowImageCopy }, ref) => {
  const { appConfig } = useContext(ConfigContext);

  const id = 'bwho';

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false
  });

  const containerRef = useRef(null);

  const WMI_Deductibles = getSafe(() => appConfig.content.deductibles.bwho.wmi);

  const WMP_Deductibles = getSafe(() => appConfig.content.deductibles.bwho.wmp);

  const WME_Deductibles = getSafe(() => appConfig.content.deductibles.bwho.wme);

  const WW_Deductibles = getSafe(() => appConfig.content.deductibles.bwho.ww);

  const bwho_Plans = ['bwho_wmi', 'bwho_wmp', 'bwho_wme', 'bwho_ww'];

  const specific_area_of_coverages = getSafe(() => appConfig.content.set2_area_of_coverage);


  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);
  }, [response, discounts]);

  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;


    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      const wmi_rate = rates[i].wmi;
      const wmp_rate = rates[i].wmi + rates[i].wmp;
      const wme_rate = rates[i].wmi + rates[i].wmp + rates[i].wme;
      const ww_rate = rates[i].wmi + rates[i].wmp + rates[i].wme + rates[i].ww;

      computedRates.push({
        'client': rates[i].client,
        'wmi': wmi_rate * totalPercent,
        'wmp': wmp_rate * totalPercent,
        'wme': wme_rate * totalPercent,
        'ww': ww_rate * totalPercent,
      })
    }

    return computedRates;
  }


  function checkIfThereIsSelected_BWHO_Plan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (bwho_Plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function scrollToView() {
    // setTimeout(() => {
    //   containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }, 200);
  }

  function onInputDiscountChange(e) {
    var numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Remove leading zeros
    numericValue = numericValue.replace(/^0+/, '');

    if (numericValue > 100) {
      numericValue = 100;
    }

    discounts[id] = numericValue;

    setDiscounts({ ...discounts });
  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2, scrollMarginTop: '200px' }} variant='outlined' ref={containerRef}>
        <Typography variant='h5' sx={{ color: '#fff', background: '#002047', px: 2, py: 1 }}>BUPA WHO</Typography>

        <Box sx={{ p: 2 }}>
          {/* <Grid container alignItems='center'>
            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Select Plans</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_wmi' />} label="Worldwide Medical Insurance" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_wmp' />} label="Worldwide Medical Plus" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_wme' />} label="Worldwide Medicines & Equipment" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_ww' />} label="Worldwide Wellbeing" />
            </Grid>
            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Optional</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Checkbox checked={assistanceCovers.evacuation}
                onChange={(e) => {
                  setAssistanceCovers({ ...assistanceCovers, evacuation: e.target.checked });
                  scrollToView();
                }} />}
                label="Evacuation" />
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Deductible</Typography>
            </Grid>
            <Grid item xs={2}>
              <CustomSelect2
                value={deductibles.bwho.wmi_deductible}
                onChange={(e) => {
                  const val = e.target.value;

                  const _deductibles = JSON.parse(JSON.stringify(deductibles));

                  _deductibles.bwho.wmi_deductible = val;

                  if (_deductibles.bwho.wmi_deductible === WMI_Deductibles[currency][0]) {
                    _deductibles.bwho.wmp_deductible = WMP_Deductibles[currency][0];
                    _deductibles.bwho.wme_deductible = WME_Deductibles[currency][0];
                  } else {
                    _deductibles.bwho.wmp_deductible = WMP_Deductibles[currency][1];
                    _deductibles.bwho.wme_deductible = WME_Deductibles[currency][1];
                  }

                  setDeductibles(_deductibles);

                  addToPendingTasks([id]);

                }}
                items={WMI_Deductibles[currency]}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelect2
                readOnly
                value={deductibles.bwho.wmp_deductible}
                items={WMP_Deductibles[currency]}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelect2
                readOnly
                value={deductibles.bwho.wme_deductible}
                items={WME_Deductibles[currency]}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelect2
                readOnly
                value={deductibles.bwho.ww_deductible}
                items={WW_Deductibles[currency]}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={12} mb={1}></Grid>
            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Area of Coverage</Typography>
            </Grid>
            <Grid item xs={2}>
              <Select
                size='small'
                value={specificAreaOfCoverage[id]}
                onChange={(e) => {
                  specificAreaOfCoverage[id] = e.target.value;
                  setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                  addToPendingTasks([id]);
                }}
                sx={{ fontSize: 12, height: 40, width: '190px' }}
                fullWidth
                disabled={loadingStatus[id]}
              >
                <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                {
                  specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                    <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                  ))
                }

              </Select>
            </Grid>
            <Grid item xs={1.5} mr={2}><Typography fontSize={18} fontWeight={600}>Renewal/Further Discount</Typography></Grid>
            <Grid item xs={1}>
              <TextField
                size='small'
                sx={{ width: '80px', textAlign: 'end' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={discounts[id]}
                onChange={onInputDiscountChange}
              />
            </Grid>
          </Grid> */}

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Typography fontSize={18} fontWeight={600} width={300}>Plan Type</Typography>
                <Typography fontSize={18} fontWeight={600}>Deductible Options</Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel sx={{ width: 300 }} control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_wmi' />} label="Worldwide Medical Insurance" />
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={deductibles.bwho.wmi_deductible}
                  onChange={(e) => {
                    const val = e.target.value;

                    const _deductibles = JSON.parse(JSON.stringify(deductibles));

                    _deductibles.bwho.wmi_deductible = val;

                    if (_deductibles.bwho.wmi_deductible === WMI_Deductibles[currency][0]) {
                      _deductibles.bwho.wmp_deductible = WMP_Deductibles[currency][0];
                      _deductibles.bwho.wme_deductible = WME_Deductibles[currency][0];
                    } else {
                      _deductibles.bwho.wmp_deductible = WMP_Deductibles[currency][1];
                      _deductibles.bwho.wme_deductible = WME_Deductibles[currency][1];
                    }

                    setDeductibles(_deductibles);

                    addToPendingTasks([id]);

                  }}
                  items={WMI_Deductibles[currency]}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel sx={{ width: 300 }} control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_wmp' />} label="Worldwide Medical Plus" />
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  readOnly
                  value={deductibles.bwho.wmp_deductible}
                  items={WMP_Deductibles[currency]}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel sx={{ width: 300 }} control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_wme' />} label="Worldwide Medicines & Equipment" />
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  readOnly
                  value={deductibles.bwho.wme_deductible}
                  items={WME_Deductibles[currency]}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel sx={{ width: 300 }} control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='bwho_ww' />} label="Worldwide Wellbeing" />
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  readOnly
                  value={deductibles.bwho.ww_deductible}
                  items={WW_Deductibles[currency]}
                  disabled={loadingStatus[id]}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Assistance Cover</Typography>
              <FormControlLabel control={<Checkbox checked={assistanceCovers.evacuation}
                onChange={(e) => {
                  setAssistanceCovers({ ...assistanceCovers, evacuation: e.target.checked });
                  scrollToView();
                }} />}
                label="Evacuation" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                />
              </Box>
            </Box>
          </Box>

          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-bwho-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id="table-bwho-rates" size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#002047', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ width: 260, color: '#fff', fontWeight: 600 }}>Plan</TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, color: '#fff', fontWeight: 600 }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div>  </TableCell>
                    })
                  }
                  <TableCell sx={{ color: '#fff', fontWeight: 600 }}>Subtotal</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='bwho_wmi'
                  planName='wmi'
                  planLabel='Worldwide Medical Insurance'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#65829b'
                  loading={loadingStatus[id]}
                  rates={response ? response.rates : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('bwho_wmi') || selectedPlans.includes('bwho_wmp') || selectedPlans.includes('bwho_wme') || selectedPlans.includes('bwho_ww')}
                />
                <TableRatesRow
                  planId='bwho_wmp'
                  planName='wmp'
                  planLabel='Worldwide Medical Plus'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#65829b'
                  loading={loadingStatus[id]}
                  rates={response ? response.rates : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('bwho_wmp') || selectedPlans.includes('bwho_wme') || selectedPlans.includes('bwho_ww')}
                />
                <TableRatesRow
                  planId='bwho_wme'
                  planName='wme'
                  planLabel='Worldwide Medicines & Equipment'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#65829b'
                  loading={loadingStatus[id]}
                  rates={response ? response.rates : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('bwho_wme') || selectedPlans.includes('bwho_ww')}
                />
                <TableRatesRow
                  planId='bwho_ww'
                  planName='ww'
                  planLabel='Worldwide Wellbeing'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#65829b'
                  loading={loadingStatus[id]}
                  rates={response ? response.rates : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('bwho_ww')}
                />
                {
                  assistanceCovers.evacuation === true &&
                  <TableRow sx={{ backgroundColor: '#ffffff' }}>
                    <TableCell colSpan={2 + clients.length} sx={{ fontWeight: 600, backgroundColor: '#fff' }}>Optional</TableCell>
                  </TableRow>
                }

                {/* <TableRow>
                  <TableCell colSpan={2 + clients.length} sx={{ fontWeight: 600 }}>Optional</TableCell>
                </TableRow> */}

                <TableRatesRow
                  planId='bwho_evacuation'
                  planName='evacuation'
                  planLabel='Evacuation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  visible={assistanceCovers.evacuation}
                />
                {/* {
                  checkIfThereIsSelected_BWHO_Plan() == false &&
                  <TableRow>
                    <TableCell>No Plan Selected</TableCell>
                  </TableRow>
                } */}
              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default BWHO_Rates;