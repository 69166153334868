import { Box, Button, Chip, CircularProgress, Paper, Typography } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import { AuthContext } from '../utils/AuthContext';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material/styles';
import { downloadFile, fetchInsurerRates, uploadFile } from '../services/InsurerRateService';
import AlertDialog from '../components/AlertDialog';
import global from "../utils/Global";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AppFileCard from '../components/AppFileCard';
import Global from '../utils/Global';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function InsurerRates() {
  const { auth, setAuth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('quoting-drawer-open') == 'true');

  const [file, setFile] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileDescription, setFileDescription] = useState('');

  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const [fileId, setFileId] = useState([]);
  const [insurerRates, setInsurerRates] = useState([]);

  const [fetchingFiles, setFetchingFiles] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user.role_id === Global.ROLE_ADMIN || auth.user.role_id === Global.ROLE_SUPER_ADMIN) {
      // User is authorized, do nothing
    } else {
      // User is not authorized, redirect
      navigate('/quotation');
    }
  }, []);

  useEffect(() => {
    handleFetchInsurerRates();
  }, []);

  useEffect(() => {
    if (isFileUploading) { return; }

    if (file != null) {
      setOpenUploadDialog(true);
    }

  }, [file]);

  async function handleFetchInsurerRates() {
    setFetchingFiles(true);

    const response = await fetchInsurerRates({ token: auth.token });

    setInsurerRates(response.data.insurer_rates);

    setFetchingFiles(false);
  }


  function handleUploadFile() {
    const formData = new FormData();
    formData.append('excel_file', file);
    formData.append('id', fileId);

    setIsFileUploading(true);

    const toastId = toast.loading("Uploading...");

    uploadFile({ body: formData, token: auth.token }).then(() => {
      toast.update(toastId, {
        type: 'success',
        render: fileDescription + ' Uploaded',
        // autoClose: 2500,
        closeButton: true,
        hideProgressBar: true,
        isLoading: false
      });

      setIsFileUploading(false);

      handleFetchInsurerRates();
    })
      .catch(err => {
        setIsFileUploading(false);
      });
  }

  function downloadRates(id) {
    // window.location.href = global.baseURL + '/quoting_api/api/insurer_rates/download_rates?id=' + id;
    downloadFile({ id, token: auth.token })
      .then((response) => {
        const str = response.headers['content-disposition'];

        const match = str.match(/filename=([^;]+)/);
        let filename = 'file.xlsx';
        if (match) {
          filename = match[1].trim();
        } else {
          console.log('Filename not found.');
        }

        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  function getLastUpdate(id) {
    const insurerRate = insurerRates.find(el => {
      return el.id === id;
    });

    if (!insurerRate) {
      return false;
    }

    return moment(insurerRate.uploaded_at).format('lll');
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 7 }} >
        <h1>Insurer Rates</h1>

        <h2>Lifeline</h2>

        <AppFileCard id='lifeline_rates'
          title='LIFELINE RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
        />
        <AppFileCard id='lifeline_assistance_cover_rates'
          title='LIFELINE ASSISTANCE COVER RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>BWHO</h2>
        <AppFileCard id='bwho_rates'
          title='BWHO RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription} />

        <AppFileCard id='bwho_assistance_cover_rates'
          title='BWHO ASSISTANCE COVER RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>COMPANY</h2>
        <AppFileCard id='company_rates'
          title='COMPANY RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <AppFileCard id='company_assistance_cover_rates'
          title='COMPANY ASSISTANCE COVER RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>OIL & GAS</h2>
        <AppFileCard id='oil_gas_rates'
          title='OIL & GAS RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />


        <h2>FIRST CHOICE</h2>
        <AppFileCard id='first_choice_rates'
          title='FIRST CHOICE RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>IHHP</h2>
        <AppFileCard id='ihhp_rates'
          title='IHHP RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>VUMI</h2>
        <AppFileCard id='vumi_rates'
          title='VUMI RATES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>MyHEALTH INDONESIA</h2>
        <AppFileCard id='my_health_indonesia_rates'
          title='MyHEALTH INDONESIA'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>MyHEALTH SINGAPORE</h2>
        <AppFileCard id='my_health_singapore_rates'
          title='MyHEALTH SINGAPORE'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>MyHEALTH VIETNAM</h2>
        <AppFileCard id='my_health_vietnam_rates'
          title='MyHEALTH VIETNAM'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        <h2>OPTIMUM GLOBAL</h2>
        <AppFileCard id='optimum_global_rates'
          title='OPTIMUM GLOBAL'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />


        <h2>MORGAN PRICE</h2>
        <AppFileCard id='morgan_price_rates'
          title='MORGAN PRICE'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload Rates'
          downloadButtonTitle='View Rates'
          fileId={fileId}
          isUploading={isFileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={downloadRates}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }} />

        {/* Space for bottom */}
        <Box sx={{ height: 100 }}></Box>
      </Main>

      <AlertDialog title={'Upload File (' + fileDescription + ')'} content={'Please confirm to upload the file <span style="font-weight:600; color:#ffc107;">' + (file ? file.name : '') + '</span>'}
        positiveButtonLabel='Confirm' negativeButtonLabel='Cancel' open={openUploadDialog} setOpen={setOpenUploadDialog}
        callback={() => {
          handleUploadFile();
          setOpenUploadDialog(false);
        }} />
    </Box>
  )
}

export default InsurerRates